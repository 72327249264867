import { text } from 'stream/consumers'
import { type Config } from 'tailwindcss'

export const extendedTheme = {
  opacity: {
    0: '0',
    5: '0.05',
    10: '0.1',
    20: '0.2',
    30: '0.3',
    40: '0.4',
    50: '0.5',
    60: '0.6',
    70: '0.7',
    80: '0.8',
    90: '0.9',
    100: '1',
  },
  colors: {
    border: 'hsl(var(--border))',
    input: {
      DEFAULT: 'hsl(var(--input))',
      invalid: 'hsl(var(--input-invalid))',
    },
    ring: {
      DEFAULT: 'hsl(var(--ring))',
      dark: 'hsl(var(--ring-dark))',
      invalid: 'hsl(var(--foreground-destructive))',
    },
    background: {
      DEFAULT: 'hsl(var(--background))',
      light: '#F2F2F2',
    },
    foreground: {
      DEFAULT: 'hsl(var(--foreground))',
      destructive: 'hsl(var(--foreground-destructive))',
    },
    primary: {
      DEFAULT: 'hsl(var(--primary))',
      foreground: 'hsl(var(--primary-foreground))',
    },
    secondary: {
      DEFAULT: 'hsl(var(--secondary))',
      foreground: 'hsl(var(--secondary-foreground))',
    },
    destructive: {
      DEFAULT: 'hsl(var(--destructive))',
      foreground: 'hsl(var(--destructive-foreground))',
    },
    muted: {
      DEFAULT: 'hsl(var(--muted))',
      foreground: 'hsl(var(--muted-foreground))',
    },
    accent: {
      DEFAULT: 'hsl(var(--accent))',
      foreground: 'hsl(var(--accent-foreground))',
    },
    popover: {
      DEFAULT: 'hsl(var(--popover))',
      foreground: 'hsl(var(--popover-foreground))',
    },
    card: {
      DEFAULT: 'hsl(var(--card))',
      foreground: 'hsl(var(--card-foreground))',
    },
    alert: {
      success: '#30D158',
      error: '#FF6262',
    },
    midnight: '#010101',
    bone: '#FFFFFF',
    brightruby: '#e02045',
    blarg: '#ff0000',
    dark: '#161616',
    light: '#F6F6F6',
    eggplant: {
      DEFAULT: '#490033',
      50: '#FFD0F1',
      100: '#FFA5E4',
      200: '#FF4ECA',
      300: '#F600AC',
      400: '#A00070',
      500: '#490033',
      600: '#3F002C',
      700: '#350025',
      800: '#2A001E',
      900: '#200016',
      950: '#1B0013',
    },
    tags: {
      yellow: '#F7F2C3',
      pink: '#EDBFB7',
      beige: '#F2DBC0',
      blue: '#CDF4E1',
      purple: '#E8E1EF',
      red: '#FA7E61',
    },
  },
  borderColor: {
    DEFAULT: 'hsl(var(--border))',
  },
  borderRadius: {
    lg: 'var(--radius)',
    md: 'calc(var(--radius) - 2px)',
    sm: 'calc(var(--radius) - 4px)',
  },
  fontFamily: {
    serif: ['Marcellus', 'serif'],
    sans: ['Satoshi-Regular'],
  },
  fontSize: {
    // 1rem = 16px
    /** 80px size / 84px high / bold */
    mega: ['5rem', { lineHeight: '5.25rem', fontWeight: '700' }],
    /** 56px size / 62px high / bold */
    h1: ['3.5rem', { lineHeight: '3.875rem', fontWeight: '700' }],
    /** 40px size / 48px high / bold */
    h2: ['2.5rem', { lineHeight: '3rem', fontWeight: '700' }],
    /** 32px size / 36px high / bold */
    h3: ['2rem', { lineHeight: '2.25rem', fontWeight: '700' }],
    /** 28px size / 36px high / bold */
    h4: ['1.75rem', { lineHeight: '2.25rem', fontWeight: '700' }],
    /** 24px size / 32px high / bold */
    h5: ['1.5rem', { lineHeight: '2rem', fontWeight: '700' }],
    /** 16px size / 20px high / bold */
    h6: ['1rem', { lineHeight: '1.25rem', fontWeight: '700' }],

    /** 32px size / 36px high / normal */
    'body-2xl': ['2rem', { lineHeight: '2.25rem' }],
    /** 28px size / 36px high / normal */
    'body-xl': ['1.75rem', { lineHeight: '2.25rem' }],
    /** 24px size / 32px high / normal */
    'body-lg': ['1.5rem', { lineHeight: '2rem' }],
    /** 20px size / 28px high / normal */
    'body-md': ['1.25rem', { lineHeight: '1.75rem' }],
    /** 16px size / 20px high / normal */
    'body-sm': ['1rem', { lineHeight: '1.25rem' }],
    /** 14px size / 18px high / normal */
    'body-xs': ['0.875rem', { lineHeight: '1.125rem' }],
    /** 12px size / 16px high / normal */
    'body-2xs': ['0.75rem', { lineHeight: '1rem' }],

    /** 18px size / 24px high / semibold */
    caption: ['1.125rem', { lineHeight: '1.5rem', fontWeight: '600' }],
    /** 12px size / 16px high / bold */
    button: ['0.75rem', { lineHeight: '1rem', fontWeight: '700' }],
  },
} satisfies Config['theme']
